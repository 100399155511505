import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/analytics';

const config = {
  apiKey: "AIzaSyBghqoyQQEE6QOR_I9tl9aqVOUA5luYRUg",
  authDomain: "quickstyle-app.firebaseapp.com",
  databaseURL: "https://quickstyle-app.firebaseio.com",
  projectId: "quickstyle-app",
  storageBucket: "quickstyle-app.appspot.com",
  messagingSenderId: "303652870271",
  appId: "1:303652870271:web:1fdd5002319aae4289c74a",
  measurementId: "G-JM7F0RS02X"
};

firebase.initializeApp(config);

export {
  firebase
};
export const auth = firebase.auth();
export const firestore = firebase.firestore();

// export default function getFirebase() {
//   if (typeof window !== 'undefined') {
//     if (instance)
//       return instance;
//     instance = firebase.initializeApp(config);
//      firebase.analytics();
//     return instance;
//   }
//
//   return null;
// }

// const displayName = activeUser.displayName;
// const email = activeUser.email;
// const emailVerified = activeUser.emailVerified;
// const photoURL = activeUser.photoURL;
// const isAnonymous = activeUser.isAnonymous;
// const uid = activeUser.uid;
// const providerData = activeUser.providerData;
